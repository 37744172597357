<template>
  <div>
    <v-row v-if="isSuperAdmin">
      <v-col class="d-flex align-center">
        <v-text-field outlined v-model="search_name" label="搜尋項目名稱" hide-details="auto"></v-text-field>
        <v-btn color="primary" class="ml-2" @click="searchEvent">搜尋</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <router-link :to="{ name: 'add-event' }">
          <v-btn color="primary">Add Event</v-btn>
        </router-link>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <!-- <data-table-with-pagination
          :headers="event_header"
          :dataList="event_list"
          :loading="load_table"
          :options="options"
          :itemLength="total_items"
          v-on:optionUpload="optionUpload"
        >
        </data-table-with-pagination> -->

        <v-data-table :loading="load_table" :headers="event_header" :items="event_list" class="table-rounded" disable-sort>
              <template v-slot:[`item.splash`]="{ item }">
                <div>
                  <v-img :src="item.splash" width="80" height="80" class="my-1"></v-img>
                </div>
              </template>
              <template v-slot:[`item.view_button`]="{ item }">
                <span>
                  <router-link :to="{ name: item.route_link, params: { id: item.id } }" style="text-decoration: none">
                    <v-btn text color="primary"> Detail </v-btn>
                  </router-link>
                </span>
              </template>
        </v-data-table>
      </v-col>
    </v-row>
    <AlertBox :type="alertBox.type" :text="alertBox.text" :show="alertBox.show"></AlertBox>
  </div>
</template>

<script>
import DataTableWithPagination from '@/components/DataTableWithPagination.vue'
import AlertBox from '@/components/AlertBox'
import { globalFunc } from '@/global'
import { mapActions } from 'vuex'
import { getData } from '@/api'
export default {
  name: 'event-management',
  components: {
    DataTableWithPagination,
    AlertBox,
  },
  data: () => ({
    isSuperAdmin: false,
    user_center_id: -1,
    verifiedError: false,
    event_header: [
      { text: 'Cover Photo', value: 'splash' },
      { text: 'Event Name', value: 'name' },
      { text: 'Event Date', value: 'start_time' },
      { text: 'Sales Amount', value: 'ticket_price' },
      { text: 'Sales Tickets', value: 'capacity' },
      { text: '', value: 'view_button' },
    ],
    // event_list: [
    //   { id: 1, splash: require('@/assets/images/images/no_image.png'), name: 'Test Event 1', start_time: '15 July 2021', ticket_price: '$2,550', capacity: '10', route_link: 'event-management-detail'},
    //   { id: 2, splash: require('@/assets/images/images/no_image.png'), name: 'Test Event 2', start_time: '13 July 2021', ticket_price: '$1,550', capacity: '20', route_link: 'event-management-detail'},
    // ],
    event_list: [],
    load_table: true,
    alertBox: {
      show: false,
      text: '',
      type: '',
    },
    total_items: 0,
    current_page: 0,
    current_limit: 10,
    options: {},
    search_name:'',
  }),
  methods: {
    ...mapActions(['reset']),
    /**
     * Initializing the event data
    */
    async initializeEventData(search_name = '') {
      this.load_table = true
      try {
        let params = new URLSearchParams()

        let send_data = {
          page: this.current_page,
          limit: this.current_limit,
        }

        console.log(send_data)
        params.set('get_all_event', null)
        let result = await getData(params)
        console.log('--- Get event data ---')
        console.log(result)

        let temp = []

        if(result) {
          for (let i = 0; i < result.length; i++) {
            let splash = "";
            if(result[i].event.splash) {
              splash = result[i].event.splash
            } else {
              splash = require('@/assets/images/images/no_image.png')
            }
            if(result[i].event.active) {
              temp.push({
                id: result[i].event.id,
                splash: splash,
                create_date: result[i].event.date,
                name: result[i].event.name,
                start_time: result[i].event.start_time,
                ticket_price: result[i].event.ticket_price,
                capacity: result[i].event.capacity,
                route_link: 'event-management-detail',
              })
            }
            
          }

          this.event_list = temp
        }
        this.load_table = false

      } catch (error) {
        console.log('--- Get event data fail ---')
        this.load_table = false
        this.event_list = []
        console.log(error)
      }

      
    },
    /**
     * Searching event
    */
    searchEvent() {
      this.initializeEventData(this.search_name)
    },
    /**
    * kicking out the user
    */
    kickout() {
      console.log('--- Validation Fail ---')
      this.alertBox.text = '登入憑證過期，3秒後重新導向'
      this.alertBox.type = 'error'
      this.alertBox.show = true

      // setTimeout(() => {
      //   this.alertBox.show = false
      //   let send_data = []
      //   send_data['method'] = 'resetData'
      //   this.reset(send_data)
      //   this.$router.push({ name: 'Login' })
      // }, 3000)
    },
    optionUpload(obj) {
      if (obj.itemsPerPage != this.current_limit) {
        if (obj.itemsPerPage == -1) {
          this.current_limit = this.total_items
        } else {
          this.current_limit = obj.itemsPerPage
        }

        this.initializeEventData()
      } else if (obj.page - 1 != this.current_page) {
        this.current_page = obj.page - 1
        this.initializeEventData()
      }
    },
  },
  /**
   * Initializing at the beginning
  */
  created() {
    setTimeout(async () => {
      console.log('--- Validation ---')
      if (globalFunc.DataValid(this.$store.state.api.login_data)) {
        try {
          this.initializeEventData()
        } catch (error) {
          console.log('--- Check User Permission Fail ---')
          console.log(error)
          this.load_table = false
          this.verifiedError = true

          this.alertBox.text = '資料取得失敗'
          this.alertBox.type = 'error'
          this.alertBox.show = true

          setTimeout(() => {
            this.alertBox.show = false
          }, 3000)
        }
      } else {
        this.kickout()
      }
    }, 200)
  },
}
</script>
